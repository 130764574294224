// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwL4qsHBJfAvkmSoiu1Mr4XkH33hQVdfw",
  authDomain: "chatlinker-a4f02.firebaseapp.com",
  projectId: "chatlinker-a4f02",
  storageBucket: "chatlinker-a4f02.appspot.com",
  messagingSenderId: "991609282928",
  appId: "1:991609282928:web:81f509017f9e37a3772fb5",
  measurementId: "G-XJLMZPP4B9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);