import React from 'react'
import "./header.css"

// import chatlinker from "../../assets/image/logo.png";

function Header() {
  return (
    <div className='header'>
    <h2>Chatlinker.chat</h2>
    {/* <img src={chatlinker}></img> */}
    <p>Instantly Message 🤳 on WhatsApp & Telegram Without Saving Contacts! 
    </p>
  </div>
  )
}

export default Header