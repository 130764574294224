import React from "react";
import "./footer.css";

function Footer() {
  return (
    <div className="footer">
      <p>
        © {new Date().getFullYear()} Copyright:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#f4ff00" }}
          href="https://www.youtube.com/@javascriptkingdom?sub_confirmation=1"
        >
          JavaScript Kingdom
        </a>
      </p>
      {/* <div style="color: black" id="total_page_view"></div> */}
    </div>
  );
}

export default Footer;
