export async function getIp() {
  try {
    const localCountryCode = getCountryCode()
    if(localCountryCode){
      return {
        country : localCountryCode
      };
    }
    const data = await fetch("https://ipinfo.io/?token=e3ea791ad8f867");
    const json = await data.json();
    if (!json.country) {
      return "in";
    }
    json.country && setCountryCode(json.country.toLowerCase())
    return json
  } catch (error) {
    console.log("IP_INFO_ERROR");
    console.log(error);
    setCountryCode("in")
    return "in";
  }
}

export function getCountryCode(){
 return localStorage.getItem("country")
}

export function setCountryCode(country){
  localStorage.setItem("country",country)
}
