import React, { useState, useEffect } from "react";

import Whatsapp from "./assets/image/whatsapp.png";
import Telegram from "./assets/image/telegram.png";
import Message from "./assets/image/message.png";
import chatlinker from "./assets/image/chatlinker.png";

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import "./main.css"

import { getIp } from "./service";


const greetings = {
    us: "Hello",
    fr: "Bonjour",
    es: "Hola",
    de: "Hallo",
    it: "Ciao",
    cn: "你好",
    jp: "こんにちは",
    in: "नमस्ते",
    // Add more country codes and greetings as needed
};


function Main() {
    const [countryCode, setCountryCode] = useState("");
    const [phone, setPhone] = useState();
    const [message, setMessage] = useState('');
    async function fetchData() {
        const response = await getIp();
        // console.clear()
        console.log(`Subscribe https://www.youtube.com/@javascriptkingdom?sub_confirmation=1
        // `);
        response.country && setCountryCode(response.country.toLowerCase());
    }

    useEffect(() => {
        fetchData();
    }, []);

    function setGreeting(countryCode) {
        const greeting = greetings[countryCode] || greetings['in']; // Default to English if country code not found
        setMessage(greeting);
    }


    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
        setTimeout(()=>{
            // window.location.reload()
            setPhone('')
            setMessage('')
        },2000)
    };

    const WhatsAppAPI = "https://api.whatsapp.com/send"
    const handleSendMessage = async (event, type) => {

        if(!phone || phone.length <=3){
            window.alert("Please enter mobile number")
            return
        }
        event.preventDefault();
        if (phone.match(/[a-z]/i)) {
            return;
        }
        const finalNumber = phone

        const miscParams = `${WhatsAppAPI}?type=phone_number&autoload=1&app_absent=1`;
        let link = `${miscParams}&phone=${finalNumber}&text=${message || 'Hi'}`;

        if (type === 'telegram') {
            link = `https://t.me/${finalNumber}`
        }

        openInNewTab(link)
    };

    return (
        <div className="main">
             {/* <button id="install-button" >Install App</button> */}
            <div className="app-container">
                <div className="app-section mt-3">
                    <div className="left-form-section ">
                        <div className="card">
                            <div className="form-section">
                                <label htmlFor="mobileNumber" className="form-input-label ">
                                    Mobile Number
                                </label>
                                <div className="mb-4">
                                    {countryCode && <PhoneInput
                                        defaultCountry={`${countryCode}`}
                                        value={phone}
                                        onChange={(phone) => setPhone(phone)}
                                        className="w-100"
                                        disableFormatting={true}
                                        autoFocus={true}
                                        disableDialCodeAndPrefix={true}
                                        showDisabledDialCodeAndPrefix={true}
                                    />
                                    }
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="message" className="form-input-label  ">
                                        Message <span className="optional"> (optional)</span>
                                    </label><br />
                                    <textarea
                                        value={message}
                                        // placeholder="Hi"

                                        onChange={(e) => setMessage(e.target.value)}
                                        id="message" className="message-input"
                                        rows="6" cols="50"
                                    ></textarea>

                                </div>
                                <div className="mb-3 ">
                                    <button className="me-3 btn success-btn  " onClick={(event) => handleSendMessage(event, "whatsapp")}>
                                        <img src={Whatsapp} alt="whatsapp" className="social-icon" />
                                        Message on WhatsApp
                                    </button><br/>
                                    <button className="me-3 btn info-btn "  onClick={(event) => handleSendMessage(event, "telegram")}>
                                        <img src={Telegram} alt="Telegram" className="social-icon" />
                                        Message on Telegram
                                    </button>
                                </div>
                                <div className="bottom-label">
                                <section>
      <h2>Privacy Assurance</h2>
      <p><strong>Rest assured, your phone numbers are never stored on our servers. They are sent directly to WhatsApp and Telegram for secure and private messaging.</strong></p>

    </section>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-image-section ">
                        <img src={chatlinker} alt="message" height={350} width={350} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
