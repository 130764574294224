import React, {useEffect} from "react";
import {logEvent  } from "firebase/analytics";

import "./App.css";
import 'react-international-phone/style.css';
import Header from "./Components/Header";
import Main from "./main";
import Footer from "./Components/Footer";

import {analytics} from './Analytics'
function App() {
  useEffect(()=>{
    logEvent(analytics, 'chatlinker');
  },[])
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
